import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  hero,
  heroText,
  line,
  mobileHero,
  mobileHero2,
  mobileHeroText,
  mobileLine,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView w="100%" boxShadow="0 2px 2px rgba(0,0,0,.5)" column center>
          <CFImage w="100%" src={hero} alt="Han Korea BBQ Restaurant Hero" />
          <CFImage
            w="100%"
            src={mobileLine}
            alt="Han Korea BBQ Restaurant Hero"
          />
          <CFView
            textCenter
            column
            center
            w="100%"
            image={`url(${mobileHero2}) bottom / cover no-repeat`}
          >
            <CFImage
              w="95%"
              src={mobileHeroText}
              mv="20px"
              alt="Han Korea BBQ Restaurant Logo Title"
            />
            <CFView mt="15px" mb="10px" pulsate>
              <OrderPickupButton />
            </CFView>

            <CFView mb="125px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
          <CFImage
            w="100%"
            src={mobileLine}
            alt="Han Korea BBQ Restaurant Hero"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={90} column center>
          <CFImage w="100%" src={hero} alt="Han Korea BBQ Restaurant Hero" />
          <CFView
            column
            center
            w="100%"
            maxWidth="1400px"
            pv="20px"
            image={`url(${mobileHero2}) center  / cover no-repeat`}
          >
            <CFImage
              w="50%"
              src={heroText}
              alt="Han Korea BBQ Restaurant Logo Title"
            />
            <CFView row center>
              <CFView mt="20px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView mt="20px" ml="15px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
          <CFImage w="100%" src={line} alt="Han Korea BBQ Restaurant Hero" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
