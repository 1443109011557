import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderDeliveryButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/0q7YrjnxUVtomLHES9JV/locations/o3mxKydEIawKfg5k6TvX`}
            >
              <CFView hover>
                <CFImage
                  src={orderDeliveryButton}
                  w="90%"
                  maxWidth="280px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/0q7YrjnxUVtomLHES9JV/locations/o3mxKydEIawKfg5k6TvX`}
            >
              <CFView hover>
                <CFImage
                  src={orderDeliveryButton}
                  w="100%"
                  maxWidth="250px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
